@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');


/* Reset margin and padding for html and body */
html,
body {
    font-family: 'Figtree', sans-serif !important;
    margin: 0;
    padding: 0px;
    height: 100%;
    scroll-behavior: smooth;
}

/* Ensure the root element takes full height */
#root {
    height: 100%;
}